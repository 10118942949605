import React from "react";

// import components
import Navbar from "../navbar";

import pdf from "../../img/blindSignature/blindsignature.pdf"

class BlindSignature extends React.Component {
  constructor() {
    super();
    this.state = {
      title: "Implémentation et attaque sur des schémas de blind signature",
      navbar: [
        {
          id: "presentation",
          text: "Présentation",
        },
        {
          id: "rapport",
          text: "Rapport",
        },
      ]
    }
  }
  componentDidMount() {
    document.title = this.state.title
  }
  render() {
    return (
      <React.Fragment>
        <Navbar forceCompact={true} values={this.state.navbar} />
        <section id="pages" className="portfolio-mf sect-pt4 sect-mt4 route">
          <div className="container">
            <div className="title-box text-center">
              <h2 className="title-a">{this.state.title}</h2>
            </div>

            <div id="presentation">
              <h3 className="text-center">Présentation</h3>
              <p className="text-justify">
                Les signatures électroniques sont des outils cryptographiques utilisés pour garantir l'authenticité des données.
                Elles sont par exemple employées pour du vote électronique ou des payements bancaires.
                La blind signature est un type de signature qui permet de préserver l'anonymat des utilisateurs.
                De nombreux schémas existent mais certains d'entre eux ne semblent pas avoir une sécurité satisfaisante.
              </p>
              <p className="text-justify">
                Dans ce projet, nous avons dans un premier temps fait l'acquisition des bases mathématiques nécessaire à la compréhension du sujet,
                puis nous avons implémenter plusieurs schémas pour appréhender les différents concepts,
                et enfin nous avons fait une analyse plus détaillée de l'un d'entre en recherchant de potentielles attaques sur ce dernier.
              </p>
            </div>

            <div id="rapport">
              <h3 className="text-center">Rapport</h3>
              <p className="text-justify">
                Mon rapport est disponible <a
                  href={pdf}
                  target="_blank"
                  rel="noopener noreferrer"
                >ici</a>.
              </p>
            </div>

          </div>
        </section>
      </React.Fragment >
    );
  }
}

export default BlindSignature;