import React from "react";

// import components
import Navbar from "../navbar";

import acceuil from "../../img/tipe/Acceuil.png"
import azimutal from "../../img/tipe/1280px-Azimuthal_equidistant_projection_SW.jpg"
import resulatBrut from "../../img/tipe/resultat brut.png"
import resulatSmooth from "../../img/tipe/resultat smooth v2.png"
import resultat2 from "../../img/tipe/resultat 2 degres.png"
import resultat05 from "../../img/tipe/resultat 0.5 degres.png"
import convexe1 from "../../img/tipe/CONVEXE PAS = 1.png"
import convexe01 from "../../img/tipe/CONVEXE PAS = 0.1.png"
import convexe005 from "../../img/tipe/CONVEXE PAS = 0.05.png"
import convexe from "../../img/tipe/convexe,s,g,pas_(464, 101),(411, 202),0.5.png"

class Tipe extends React.Component {
  constructor() {
    super();
    this.state = {
      title: "Projet TIPE océan",
      navbar: [
        {
          id: "introduction",
          text: "Introduction",
        },
        {
          id: "objectif",
          text: "Objectif",
        },
        {
          id: "image",
          text: "Image",
        },
        {
          id: "graphe",
          text: "Graphe",
        },
        {
          id: "convexe",
          text: "Espaces convexes",
        },
      ]
    }
  }
  componentDidMount() {
    document.title = this.state.title
  }
  render() {
    return (
      <React.Fragment>
        <Navbar forceCompact={true} values={this.state.navbar} />
        <section id="pages" className="portfolio-mf sect-pt4 sect-mt4 route">
          <div className="container">
            <div className="title-box text-center">
              <h2 className="title-a">{this.state.title}</h2>
            </div>

            <div id="introduction">
              <h3 className="text-center">Introduction</h3>
              <p className="text-justify">
                Le TIPE (Travaux d'Initiative Personnelle Encadrés) est un projet en 2<sup>ème</sup> année de CPGE.
                Aimant l'informatique et les mathématiques, j'ai fait un TIPE de recherche numérique sur le thème de l'océan.
              </p>
              <div className="work-img text-center">
                <img src={acceuil} alt="" className="img-fluid" />
              </div>
            </div>

            <div id="objectif">
              <h3 className="text-center">Objectif du projet</h3>
              <p className="text-justify">
                Comment choisir un chemin optimal sur l'océan malgré les obstacles (terres) ?
              </p>
              <lu>
                <li>
                  Trouver une bijection entre les coordonnées GPS et les coordonnées d'une carte (azimutale équidistante) sous forme d'image.
                </li>
                <li>
                  Construire 2 graphes et y appliquer des algorithmes de recherche de chemin (Dijkstra et A*) : un graphe discret et un graphe avec des espaces convexes.
                </li>
                <li>
                  Comparer les algorithmes et leur complexités.
                </li>
                <li>
                  Comparer les 2 graphes.
                </li>
              </lu>
              <p className="text-justify">
                Aujourd'hui la navigation est très répandue, donc optimiser les trajets est important.
                Je vais étudier le problème en général, c'est-à-dire proposer une solution de trajet le plus court entre 2 points quelconques sur l'océan.
                La recherche d'un trajet le plus court est un problème courant en informatique.
                Le plus ancien ouvrage concerné par ce type de problème est peut-être le déplacement d'un véhicule autonome sur Mars.
              </p>
              <p className="text-justify">
                Cela nécessite un graphe constitué d'un ensemble de points, chaque point ayant des voisins reliés par des arêtes.
                Ce graphe sera construit grâce à une carte azimutale équidistante qui permettra de savoir si un point sur Terre représenté par sa longitude et latitude est dans l'eau ou sur un contient.
              </p>
              <p className="text-justify">
                Je propose deux approches différentes : une simple qui prend en compte un poids (qui peut être en fonction de la distance, du vent, du courant, etc.) entre chaque arête du graphe et une autre qui va créer des espaces convexes permettant de faciliter le problème mais seulement pour la distance.
                Il faut ensuite appliquer des algorithmes de recherche de chemin sur les graphes, il en existe 2 principaux : l'algorithme de Dijkstra qui permet de déterminer le chemin optimal et l'algorithme A* qui est beaucoup plus rapide à condition d'avoir une bonne fonction heuristique.
                Le choix de la carte azimutale équidistante est intéressant pour faciliter la bijection entre image et longitude/latitude mais aussi pour sa bonne précision (hormis le pôle Nord).
                Pour améliorer les résultats, il suffit de prendre une carte de meilleure définition et d'avoir un graphe de poids.
              </p>
              <p className="text-justify">
                L'étude des trajets permet de donner des chemins optimaux pour se déplacer sur l'océan.
              </p>
            </div>

            <div id="image">
              <h3 className="text-center">Création de l'image</h3>
              <p className="text-justify">
                J'ai codé un programme pour transformer la première image en vert et bleu.
                Il a fallu ensuite passer une seconde couche pour enlever certaines lignes qui sont restées.
                Puis ensuite enlever à la main certains pixels.
                Le résultat est donc une carte (azimutale équidistante) sous forme d'image en vert et bleu qui est utilisée pour générer les graphes.
              </p>
              <div className="row text-center">
                <div className="col-sm-4">
                  <div className="work-img">
                    <img src={azimutal} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="work-img">
                    <img src={resulatBrut} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="work-img">
                    <img src={resulatSmooth} alt="" className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>

            <div id="graphe">
              <h3 className="text-center">Création du graphe</h3>
              <p className="text-justify">
                Voici la création du graphe avec un delta entre les points de 2 degrés et 0.5 degrés.
                Je mets des nœuds là où il y a de l'eau et je connecte les nœuds avec leurs voisins (même si il y a de la terre au milieu).
                Car en diminuant le delta, cela marche mieux.
              </p>
              <div className="row text-center">
                <div className="col-sm-6">
                  <div className="work-img">
                    <img src={resultat2} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="work-img">
                    <img src={resultat05} alt="" className="img-fluid" />
                  </div>
                </div>
              </div>
              <p className="text-justify">
                Implémentation des parcours de graphes Dijkstra et A* avec le nuage de points parcouru pour chaque algorithme :
              </p>
              <div className="text-center work-img">
                <img src={acceuil} alt="" className="img-fluid" />
              </div>
            </div>

            <div id="convexe">
              <h3 className="text-center">Création d'espace convexe</h3>
              <div className="row text-center">
                <div className="col-sm-4">
                  <div className="work-img">
                    <img src={convexe1} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="work-img">
                    <img src={convexe01} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="work-img">
                    <img src={convexe005} alt="" className="img-fluid" />
                  </div>
                </div>
                <p className="text-justify">
                  Ici l'objectif est de créer des espaces convexes simples (des rectangles) pour pouvoir les parcourir en suivant des belles géodésiques.
                </p>
              </div>
              <div className="text-center work-img">
                <img src={convexe} alt="" className="img-fluid" />
              </div>
            </div>

          </div>
        </section>
      </React.Fragment >
    );
  }
}

export default Tipe;