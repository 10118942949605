import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGraduationCap } from "@fortawesome/free-solid-svg-icons";

class About extends React.Component {
  constructor() {
    super();
    this.about_me = [
      {
        id: "first-p-about",
        content:
          "Passionné depuis toujours par les systèmes complexes, je ",
      },
      {
        id: "second-p-about",
        content:
          "En tant qu'ingénieur informaticien, ma rigueur et mon goût pour la réflexion approfondie me permettent de mener à bien des projets complexes et exigeants. Je m'attache à comprendre en profondeur les besoins et à concevoir des solutions robustes et pérennes. Mon approche méthodique et mon attention aux détails garantissent des résultats de haute qualité. Passionné par les défis intellectuels, je suis toujours prêt à analyser et résoudre des problématiques complexes avec une grande précision.",
      },
      {
        id: "third-p-about",
        content:
          "Lorem ipsum pariatur consectetur laboris occaecat nulla aliqua irure ad deserunt duis.",
      },
    ];
    this.parcours = [
      {
        title: "Diplome d'ingénieur Informatique",
        description:
          "ISIMA (Institut Supérieur d’Informatique, de Modélisation et de leurs Applications)",
      },
      {
        title: "MPSI MP (Math SUP/SPE)",
        description:
          "Classes Préparatoires aux Grandes Écoles (CPGE) Mathématique et Informatique",
      },
      {
        title: "BAC",
        description:
          "Baccalauréat scientifique option sciences de l'ingénieur avec mention très bien",
      },
      { title: "Brevet", description: "Mention très bien" },
    ];
  }
  render() {
    return (
      <section id="about" className="about-mf sect-pt4 route">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="box-shadow-full">
                <div className="row">
                  <div className="col-md-6">
                    <div className="about-me pt-4 pt-md-0">
                      <div className="title-box-2">
                        <h3 className="title-left">À propos de moi</h3>
                      </div>
                      {this.about_me.map((content) => {
                        return (
                          <p className="lead text-justify" key={content.id}>
                            {content.content}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="about-me pt-4 pt-md-0">
                      <div className="title-box-2">
                        <h3 className="title-left">Mon parcours académique</h3>
                      </div>
                      {this.parcours.map((content) => {
                        return (
                          <React.Fragment key={content.title}>
                            <h4>
                              <FontAwesomeIcon size={"xs"} icon={faGraduationCap} />
                              {" "+content.title}
                            </h4>
                            <p className="lead text-justify">{content.description}</p>
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
