import React from "react";
import imageOverlay from "../img/earth.jpg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookMessenger, faLinkedin, faYoutube, faGithub, faFacebook} from '@fortawesome/free-brands-svg-icons'




class Contact extends React.Component {
   constructor() {
    super();
    this.contacts = [
      { id: "linkedin", logo: faLinkedin, lien: "https://www.linkedin.com/in/guillaume-garros", },
      { id: "messenger", logo: faFacebookMessenger, lien: "https://m.me/redguigui.grs", },
      { id: "youtube", logo: faYoutube, lien: "https://www.youtube.com/channel/UCh-loCnag5vx8AVDTU-xvGA/videos", },
      { id: "github", logo: faGithub, lien: "https://github.com/RedGuigui", },
      { id: "facebook", logo: faFacebook, lien: "https://www.facebook.com/redguigui.grs", },
    ]
  }
  render() {
    return (
      <div id="contact">
      <section
        className="paralax-mf footer-paralax bg-image sect-mt4 route"
        style={{ backgroundImage: "url(" + imageOverlay + ")" }}
      >
        <div className="container">
          <div className="overlay-mf"></div>
          <div className="row">
            <div className="col-sm-12">
              <div className="title-box text-center">
                <h2 className="title-a">Retrouvez-moi sur ...</h2>
                <div className="line-mf"></div>
                <div className="socials">
                  <ul>
                    {this.contacts.map(contact => {
                      return (
                        <React.Fragment key={contact.id}>
                        <li>
                          <a
                            href={contact.lien}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {/* <span className="ico-circle">
                              <i className={contact.logo}></i>
                            </span> */}
                            <span className="ico-circle">
                              <FontAwesomeIcon icon={contact.logo} />
                            </span>
                          </a>
                        </li>
                        </React.Fragment>
                      );
                    })}
                  </ul> 
                </div>
                <div className="line-mf"></div>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="copyright-box">
                  <div className="col">
                    <a
                      href="https://github.com/shloksomani/react-portfolio"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                    React-Portfolio
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </section>
      </div>
    );
  }
}

export default Contact;
