import React from "react";

// import components
import Navbar from "../navbar";

import acceuil from "../../img/bdd/Acceuil.png"
import ajoutEnseignant from "../../img/bdd/AjoutEnseignant.png"
import modifiEnseignant1 from "../../img/bdd/ModifiEnseignant1.png"
import modifiEnseignant2 from "../../img/bdd/ModifiEnseignant2.png"
import suppressEnseigant from "../../img/bdd/SuppressEnseignant.png"
import nbCours from "../../img/bdd/NbCoursEnseignant.png"
import augmentation from "../../img/bdd/Augmentation.png"
import nbtToth from "../../img/bdd/NbTotH.png"
import lCourEns1 from "../../img/bdd/LCourEns1.png"
import lCourEns2 from "../../img/bdd/LCourEns2.png"

class Bdd extends React.Component {
  constructor() {
    super();
    this.state = {
      title: "Application web d'une base de donnée",
      navbar: [
        {
          id: "objectif",
          text: "Objectif",
        },
        {
          id: "ajouter",
          text: "Ajouter",
        },
        {
          id: "modifier",
          text: "Modifier",
        },
        {
          id: "supprimer",
          text: "Supprimer",
        },
        {
          id: "nbcours",
          text: "NbCours",
        },
        {
          id: "procedure",
          text: "Procédure",
        },
        {
          id: "nbtotal",
          text: "NbTotal",
        },
        {
          id: "liste",
          text: "Liste",
        },
      ]
    }
  }
  componentDidMount() {
    document.title = this.state.title
  }
  render() {
    return (
      <React.Fragment>
        <Navbar forceCompact={true} values={this.state.navbar} />
        <section id="pages" className="portfolio-mf sect-pt4 sect-mt4 route">
          <div className="container">
            <div className="title-box text-center">
              <h2 className="title-a">{this.state.title}</h2>
            </div>

            <div id="objectif">
              <h3 className="text-center">Objectif du projet</h3>
              <p className="text-justify">
                Faire un site (simple) de gestion des cours d'ISIMA.
                Ce site utilise phpMyAdmin et interroge en temps réel la base de donnée que nous avons créée.
                (Ce ne sont que des captures d'écrans pour des raisons de sécurité)
              </p>
              <p className="text-justify">
                Le but de ce projet est de découvrir les différentes méthodes d'une application web.
              </p>
              <p className="text-justify">
                Nous sommes 2 sur ce projet mais j'ai fait 92% du travail.
              </p>
              <div className="work-img text-center">
                <img src={acceuil} alt="" className="img-fluid" />
              </div>
            </div>

            <div id="ajouter">
              <h3 className="text-center">Ajouter un enseignant</h3>
              <div className="work-img text-center">
                <img src={ajoutEnseignant} alt="" className="img-fluid" />
              </div>
            </div>

            <div id="modifier">
              <h3 className="text-center">Modifier un enseignant</h3>
              <div className="row text-center">
                <div className="col-sm-6">
                  <div className="work-img">
                    <img src={modifiEnseignant1} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="card col-sm-6">
                  <div className="work-img">
                    <img src={modifiEnseignant2} alt="" className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>

            <div id="supprimer">
              <h3 className="text-center">Supprimer un enseignant</h3>
              <div className="work-img text-center">
                <img src={suppressEnseigant} alt="" className="img-fluid" />
              </div>
            </div>

            <div id="nbcours">
              <h3 className="text-center">Nombre de cours et d'enseignants</h3>
              <div className="work-img text-center">
                <img src={nbCours} alt="" className="img-fluid" />
              </div>
            </div>

            <div id="procedure">
              <h3 className="text-center">Lancer la procédure AUGMENTATION</h3>
              <div className="work-img text-center">
                <img src={augmentation} alt="" className="img-fluid" />
              </div>
            </div>

            <div id="nbtotal">
              <h3 className="text-center">Lancer la fonction NB_TOT_H</h3>
              <div className="work-img text-center">
                <img src={nbtToth} alt="" className="img-fluid" />
              </div>
            </div>

            <div id="liste">
              <h3 className="text-center">Lancer la fonction NB_TOT_H</h3>
              <div className="row text-center">
                <div className="col-sm-6">
                  <div className="work-img">
                    <img src={lCourEns1} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="card col-sm-6">
                  <div className="work-img">
                    <img src={lCourEns2} alt="" className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>

          </div>
        </section>
      </React.Fragment >
    );
  }
}

export default Bdd;