import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';

class BackToTop extends React.Component {
    componentDidMount() {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 100) {
                document.querySelector('.back-to-top').classList.remove("fadeOut");
                document.querySelector('.back-to-top').style.display = "block";
                document.querySelector('.back-to-top').classList.add("fadeIn");
            } else {
                document.querySelector('.back-to-top').classList.remove("fadeIn");
                document.querySelector('.back-to-top').classList.add("fadeOut");
            }
        });
    }

    render() {
        return (
            // <button type="button" className="back-to-top animated" href="#home" title="Retour en haut">
            //     <FontAwesomeIcon icon={faChevronUp} />
            // </button>
            <a href="#home">
                <button type="button" className="back-to-top animated" title="Retour en haut">
                    <FontAwesomeIcon icon={faChevronUp} />
                </button>
            </a>
        );
    }
}

export default BackToTop;