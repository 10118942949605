import React from "react";

// import components
import Navbar from "../navbar";

import gif from "../../img/openindoor/gif.gif";
import pdf from "../../img/openindoor/openindoor.pdf";

class Openindoor extends React.Component {
  constructor() {
    super();
    this.state = {
      title: "Stage chez OpenIndoor",
      navbar: [
        {
          id: "objectif",
          text: "Objectif",
        },
        {
          id: "rapport",
          text: "Rapport",
        },
        {
          id: "exemple",
          text: "Exemples",
        },
      ]
    }
  }
  componentDidMount(){
    document.title = this.state.title
  }
  render() {
    return (
      <React.Fragment>
        <Navbar forceCompact={true} values={this.state.navbar} />
        <section id="pages" className="portfolio-mf sect-pt4 sect-mt4 route">
          <div className="container">
            <div className="title-box text-center">
              <h2 className="title-a">{this.state.title}</h2>
              <div className="work-img">
                <img src={gif} alt="" className="img-fluid" />
              </div>
            </div>

            <div id="objectif">
              <h3 className="text-center">Objectif du Stage</h3>
              <p className="text-justify">
                Stage de 5 mois en R&D pour l'entreprise OpenIndoor. Le but du stage et de catégoriser les données des bâtiments et d'exploiter cette catégorisation.
              </p>
              <p className="text-justify">
                OpenIndoor est une jeune startup française qui permet de faire des rendus 3D de bâtiments et du wayfinding multi-niveaux dans un bâtiment.
              </p>
            </div>

            <div id="rapport">
              <h3 className="text-center">Rapport de stage</h3>
              <p className="text-justify">
                Mon rapport de stage est disponible <a
                  href={pdf}
                  target="_blank"
                  rel="noopener noreferrer"
                >ici</a>.
              </p>
            </div>

            <div id="exemple">
              <h3 className="text-center">Quelques exemples en ligne</h3>
              <p className="text-justify">
                J'ai volontairement travaillé sur plusieurs projets pour m'améliorer et découvrir un maximum de nouvelles technologies.
              </p>
              <p className="text-justify">
                Le plus gros de mon travail pouvais être regardé sur l'application principale de OpenIndoor : <a
                  href="https://app.openindoor.io/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://app.openindoor.io/
                </a>.
              </p>
              <p className="text-justify">
                J'ai intégré la coloration extérieur des bâtiments, le menu à gauche qui permet l'activation de cette coloration.
                De plus j'ai rajouté des Pop-up : une pour les itinéraire et une pour avoir des informations sur le bâtiment cliqué.
              </p>
            </div>

          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Openindoor;