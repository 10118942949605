import React from "react";

// import components
import Navbar from "../navbar";

import pdf from "../../img/apiProject/API_projet.pdf";

class ApiProject extends React.Component {
  constructor() {
    super();
    this.state = {
      title: "Développement d'une interface graphique pour interroger les API de plusieurs réseaux sociaux",
      navbar: [
        {
          id: "presentation",
          text: "Présentation",
        },
        {
          id: "rapport",
          text: "Rapport",
        },
      ]
    }
  }
  componentDidMount() {
    document.title = this.state.title
  }
  render() {
    return (
      <React.Fragment>
        <Navbar forceCompact={true} values={this.state.navbar} />
        <section id="pages" className="portfolio-mf sect-pt4 sect-mt4 route">
          <div className="container">
            <div className="title-box text-center">
              <h2 className="title-a">{this.state.title}</h2>
            </div>

            <div id="presentation">
              <h3 className="text-center">Présentation</h3>
              <p className="text-justify">
                Le but de ce projet est d'interroger les Interfaces de Programmation d'Applications (API) de différents réseaux sociaux pour en extraire une quantité importante de données et pouvoir ensuite dessiner des graphes à partir des données collectées.
              </p>
              <p className="text-justify">
                Pour cela, nous avons étudié le fonctionnement d'API de différents réseaux sociaux pour pouvoir développer en Python des applications les interrogeant, puis une application permettant de réaliser des graphes.
              </p>
              <p className="text-justify">
                La collecte de données grâce aux API a été effectuée avec succès, et des graphes ont pu être réalisés avec ces données.
                Néanmoins, l'application permettant de réaliser des graphes contient, au moment du rendu de ce rapport, encore quelques bugs à corriger.
              </p>
            </div>

            <div id="rapport">
              <h3 className="text-center">Rapport</h3>
              <p className="text-justify">
                Mon rapport est disponible <a
                  href={pdf}
                  target="_blank"
                  rel="noopener noreferrer"
                >ici</a>.
              </p>
            </div>

          </div>
        </section>
      </React.Fragment >
    );
  }
}

export default ApiProject;