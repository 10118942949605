import React from 'react';

class Preloader extends React.Component {
    componentDidMount(){
        window.addEventListener('load', this.handleLoad);
    }

    componentWillUnmount() {
        window.removeEventListener('load', this.handleLoad);
    }

    handleLoad = () => {
        const preloader = document.getElementById('preloader');
        if (preloader) {
            setTimeout(() => {
                preloader.style.transition = 'opacity 0.6s';
                preloader.style.opacity = '0';
                setTimeout(() => {
                    preloader.remove();
                }, 600);
            }, 100);
        }
    }

    render(){
        return <div id="preloader"></div>;
    }
}

export default Preloader;