import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightLong } from "@fortawesome/free-solid-svg-icons";

// import components
import Navbar from "../navbar";

import choix_taille from "../../img/chocolat/choix_taille.png"
import ia_hard_1 from "../../img/chocolat/ia_hard_1.png"
import ia_hard_2 from "../../img/chocolat/ia_hard_2.png"
import menu from "../../img/chocolat/menu.png"
import tablette_4x4 from "../../img/chocolat/tablette_4x4.png"
import tablette_4x4_1 from "../../img/chocolat/tablette_4x4_1.png"
import tablette_4x4_2 from "../../img/chocolat/tablette_4x4_2.png"
import tablette_4x4_3 from "../../img/chocolat/tablette_4x4_3.png"
import tablette_4x4_4 from "../../img/chocolat/tablette_4x4_4.png"
import tablette_4x4_5 from "../../img/chocolat/tablette_4x4_5.png"
import zoom from "../../img/chocolat/zoom.png"

class Chocolat extends React.Component {
  constructor() {
    super();
    this.state = {
      title: "Le jeu du chocolat M.A.Th en J.E.A.N.S.",
      navbar: [
        {
          id: "presentation",
          text: "Présentation",
        },
        {
          id: "objectif",
          text: "Objectif",
        },
        {
          id: "travail",
          text: "Travail",
        },
        {
          id: "implementation",
          text: "Implémentation",
        },
        {
          id: "exemple",
          text: "Exemple",
        },
        {
          id: "zoom",
          text: "Zoom",
        },
        {
          id: "ia",
          text: "IA",
        },
      ]
    }
  }
  componentDidMount() {
    document.title = this.state.title
  }
  render() {
    return (
      <React.Fragment>
        <Navbar forceCompact={true} values={this.state.navbar} />
        <section id="pages" className="portfolio-mf sect-pt4 sect-mt4 route">
          <div className="container">
            <div className="title-box text-center">
              <h2 className="title-a">{this.state.title}</h2>
            </div>

            <div id="presentation">
              <h3 className="text-center">Présentation</h3>
              <p className="text-justify">
                Le jeu du chocolat est un jeu à deux joueurs. Un jeu à deux joueurs.
                Il consiste en une tablette de chocolat de taille m par n dont le carré bas-gauche est empoisonné.
                À tour de rôle, chaque joueur "croque" un carré de la tablette en retirant en même temps tous les carrés à la droite et au dessus.
                Le joueur qui mange le carré empoisonné perd.
              </p>
              <div className="row text-center">
                <div className="card col-sm-5">
                  <div className="card-body">

                    <h4>Initiale</h4>
                    <div className="work-img">
                      <img src={tablette_4x4} alt="" className="img-fluid" />
                    </div>
                  </div>
                </div>
                <div className="col-sm-2 ac-center">
                  <FontAwesomeIcon size={"3x"} icon={faRightLong} />
                </div>

                <div className="card col-sm-5">
                  <div className="card-body">
                    <h4>Après</h4>
                    <div className="work-img">
                      <img src={tablette_4x4_1} alt="" className="img-fluid" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="objectif">
              <h3 className="text-center">Objectif du projet</h3>
              <p className="text-justify">
                Travailler à plusieur pour trouver une stratégie gagnante.
              </p>
            </div>

            <div id="travail">
              <h3 className="text-center">Travail</h3>
              <p className="text-justify">
                Nous avons trouvé une stratégie gagnante pour le premier joueur pour une tablette de taille n*n et 2*n.
              </p>
              <p className="text-justify">
                Vous pouvez trouver la page du projet sur le site <a
                  href={"http://www.mathenjeans.fr/content/lycee-pierre-mendes-france-vitrolles-2016-2017"}
                  target="_blank"
                  rel="noopener noreferrer"
                >M.A.Th en J.E.A.N.S.</a>
                <br></br>
                Ainsi que le pdf résumant tout sur le site du lycée <a
                  href={"https://www.lyc-mendesfrance-vitrolles.ac-aix-marseille.fr/spip/sites/www.lyc-mendesfrance-vitrolles/spip/IMG/pdf/chocolate_game_-_lycee_pierre_mendes_france_vitrolles.pdf"}
                  target="_blank"
                  rel="noopener noreferrer"
                >Pierre Mendes France</a>.
              </p>
            </div>

            <div id="implementation">
              <h3 className="text-center">Implémentation</h3>
              <p className="text-justify">
                J'ai codé de A à Z une implémentation du jeu du chocolat sur la calculatrice.
                Ainsi qu'une IA la meilleure possible quand le jeu est en 2*n, et sinon une IA aléatoire.
              </p>
              <div className="row text-center">
                <div className="card col-sm-6">
                  <div className="card-body">
                    <h4>Menu</h4>
                    <div className="work-img">
                      <img src={menu} alt="" className="img-fluid" />
                    </div>
                  </div>
                </div>

                <div className="card col-sm-6">
                  <div className="card-body">
                    <h4>Choix de la taille</h4>
                    <div className="work-img">
                      <img src={choix_taille} alt="" className="img-fluid" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="exemple">
              <h3 className="text-center">Exemple</h3>
              <p className="text-justify">
                Exemple d'une partie à 2 joueurs :
              </p>
              <div className="row text-center">
                <div className="col-sm-5">
                  <div className="work-img">
                    <img src={tablette_4x4_1} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="col-sm-2 ac-center">
                  <FontAwesomeIcon size={"3x"} icon={faRightLong} />
                </div>
                <div className="col-sm-5">
                  <div className="work-img">
                    <img src={tablette_4x4_2} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="col-sm-5">
                  <div className="work-img">
                    <img src={tablette_4x4_3} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="col-sm-2 ac-center">
                  <FontAwesomeIcon size={"3x"} icon={faRightLong} />
                </div>
                <div className="col-sm-5">
                  <div className="work-img">
                    <img src={tablette_4x4_4} alt="" className="img-fluid" />
                  </div>
                </div>
                <div className="col-sm center">
                  <div className="work-img">
                    <img src={tablette_4x4_5} alt="" className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>

            <div id="zoom">
              <h3 className="text-center">Zoom</h3>
              <p className="text-justify">
                Possibilité de "zoomer l'écran" à n'importe quel moment en appuyant sur +.
                Par exemple avec le dernier état de la tablette ci-dessus :
              </p>
              <div className="row text-center">
                <div className="card col-sm-6">
                  <div className="card-body">
                    <div className="work-img">
                      <img src={zoom} alt="" className="img-fluid" />
                    </div>
                  </div>
                </div>

                <div className="card col-sm-6">
                  <div className="card-body">
                    <div className="work-img">
                      <img src={tablette_4x4_5} alt="" className="img-fluid" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="ia">
              <h3 className="text-center">Intelligence Artificielle</h3>
              <p className="text-justify">
                Un exemple de mon intellifence artificielle pour une tablette 2x8.
              </p>
              <div className="row text-center">
                <div className="card col-sm-6">
                  <div className="card-body">
                    <div className="work-img">
                      <img src={ia_hard_1} alt="" className="img-fluid" />
                    </div>
                  </div>
                </div>

                <div className="card col-sm-6">
                  <div className="card-body">
                    <div className="work-img">
                      <img src={ia_hard_2} alt="" className="img-fluid" />
                    </div>
                  </div>
                </div>
              </div>

              <p className="text-justify">
                L'IA a joué en bas à droite et a activé la stratégie gagnante.
                Maintenant, à la fin de la partie, le joueur aura perdu et l'IA aura gagné.
              </p>
            </div>

          </div>
        </section>
      </React.Fragment >
    );
  }
}

export default Chocolat;