import React from "react";

// import components
import Navbar from "../navbar";

import Video1 from "../../img/clavier/old.webm";
import Video2 from "../../img/clavier/clavier_nouv.webm";

class Clavier extends React.Component {
  constructor() {
    super();
    this.state = {
      title: "Projet personnel clavier",
      navbar: [
        {
          id: "presentation",
          text: "Présentation",
        },
        {
          id: "approfondissement",
          text: "Approfondissement",
        },
      ]
    }
  }
  componentDidMount() {
    document.title = this.state.title
  }
  render() {
    return (
      <React.Fragment>
        <Navbar forceCompact={true} values={this.state.navbar} />
        <section id="pages" className="portfolio-mf sect-pt4 sect-mt4 route">
          <div className="container">
            <div className="title-box text-center">
              <h2 className="title-a">{this.state.title}</h2>
            </div>

            <div id="presentation">
              <h3 className="text-center">Présentation</h3>
              <p className="text-justify">
                Mon programme, écrit en C avec la bibliothèque SDL2, permet d'apprendre à utiliser tous ses doigts lorsqu'on tape sur le clavier.
                Les phrases d'apprentissage proviennent du petit livre "Apprentissage rapide du clavier" de Monique Langlet.
                Il y a 18 leçons, chacune comportant 8 exercices.
              </p>
              <video width="100%" height="100%" controls autoPlay loop muted>
                <source src={Video1} type="video/webm" />
              </video>
            </div>

            <div id="approfondissement">
              <h3 className="text-center">Approfondissement</h3>
              <p className="text-justify">
                Après avoir assimilé quelles touches sont réservées à quel doigt, j'ai voulu créer un programme permettant de taper tous les mots du dictionnaire.
                Pour cela, j'ai décidé de le développer en C++ cette fois-ci, afin d'améliorer mes compétences dans ce langage.
              </p>
              <p className="text-justify">
                L'entièreté de ce projet est disponible en libre accès sur gitlab : https://gitlab.isima.fr/gugarros/learn_keyboard.
              </p>
              <video width="100%" height="100%" controls loop muted>
                <source src={Video2} type="video/webm" />
              </video>
            </div>

          </div>
        </section>
      </React.Fragment >
    );
  }
}

export default Clavier;