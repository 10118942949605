import React from "react";

// import components
import Navbar from "../navbar";

import Demo1laby from "../../img/labyrinthe/_Demo1laby.webm"
import DemoA from "../../img/labyrinthe/_DemoA.webm"
import DemoB from "../../img/labyrinthe/_DemoB.webm"
import DemoD from "../../img/labyrinthe/_DemoD.webm"
import DemoFC from "../../img/labyrinthe/_DemoFC.webm"
import DemoGN from "../../img/labyrinthe/_DemoGN.webm"
import DemoV from "../../img/labyrinthe/_DemoV.webm"
import DemoW from "../../img/labyrinthe/_DemoW.webm"
import DemoX from "../../img/labyrinthe/_DemoX.webm"
import DemoZ from "../../img/labyrinthe/_DemoZ.webm"

class Labyrinthe extends React.Component {
  constructor() {
    super();
    this.state = {
      title: "Projet labyrinthe",
      navbar: [
        {
          id: "introduction",
          text: "Introduction",
        },
        {
          id: "objectif",
          text: "Objectif",
        },
        {
          id: "tuile",
          text: "Tuile",
        },
        {
          id: "vision",
          text: "Vision",
        },
        {
          id: "brouillard",
          text: "Brouillard",
        },
        {
          id: "personnage",
          text: "Personnage",
        },
        {
          id: "dfs",
          text: "DFS",
        },
        {
          id: "dijkstra",
          text: "Dijkstra",
        },
        {
          id: "astar",
          text: "Astar",
        },
        {
          id: "pieces",
          text: "Pièces",
        },
        {
          id: "ia",
          text: "IA",
        },
      ]
    }
  }
  componentDidMount() {
    document.title = this.state.title
  }
  render() {
    return (
      <React.Fragment>
        <Navbar forceCompact={true} values={this.state.navbar} />
        <section id="pages" className="portfolio-mf sect-pt4 sect-mt4 route">
          <div className="container">
            <div className="title-box text-center">
              <h2 className="title-a">{this.state.title}</h2>
            </div>

            <div id="introduction">
              <h3 className="text-center">Introduction</h3>
              <video width="100%" height="100%" controls autoPlay loop muted>
                <source src={Demo1laby} type="video/webm" />
              </video>
            </div>

            <div id="objectif">
              <h3 className="text-center">Objectif du projet</h3>
              <p className="text-justify">
                Projet de fin de 1<sup>ère</sup> année sur le thème du labyrinthe.
                C'est un jeu mais surtout une vitrine de tout ce qu'on à réussi à faire en ce temps imparti.
              </p>
            </div>

            <div id="tuile">
              <h3 className="text-center">Changement des tuiles</h3>
              <p className="text-justify">
                Nous pouvons changer l'affichage des coins des murs du labyrinthe ainsi que la texture des tuiles (en appuyant sur C et F).
              </p>
              <video width="100%" height="100%" controls loop muted>
                <source src={DemoFC} type="video/webm" />
              </video>
            </div>

            <div id="vision">
              <h3 className="text-center">Changement de la Vision</h3>
              <p className="text-justify">
                Nous pouvons activer/désactiver l'affichage du chemin parcouru (en appuyant sur V).
              </p>
              <video width="100%" height="100%" controls loop muted>
                <source src={DemoV} type="video/webm" />
              </video>
            </div>

            <div id="brouillard">
              <h3 className="text-center">Brouillard</h3>
              <p className="text-justify">
                Nous pouvons activer/désactiver l'affichage du brouillard (en appuyant sur B, Il y a 3 brouillards différents).
              </p>
              <video width="100%" height="100%" controls loop muted>
                <source src={DemoB} type="video/webm" />
              </video>
            </div>

            <div id="personnage">
              <h3 className="text-center">Changer de personnage</h3>
              <p className="text-justify">
                Nous pouvons changer de personnage et ainsi changer la couleur de notre pistolet laser (en appuyant sur N, il y a 4 personnages différents).
              </p>
              <p className="text-justify">
                Nous pouvons également changer le type du pistolet pour tous les personnages(en appuyant sur G, il y a 8 pistolets différents).
              </p>
              <video width="100%" height="100%" controls loop muted>
                <source src={DemoGN} type="video/webm" />
              </video>
            </div>

            <div id="dfs">
              <h3 className="text-center">Parcours en profondeur</h3>
              <p className="text-justify">
                Nous avons implémenté un parcours en profondeur animé du labyrinthe.
              </p>
              <video width="100%" height="100%" controls loop muted>
                <source src={DemoW} type="video/webm" />
              </video>
            </div>

            <div id="dijkstra">
              <h3 className="text-center">Algorithme de Dijkstra</h3>
              <p className="text-justify">
                Nous avons implémenté l'algorithme de Dijkstra (animé) du labyrinthe (en appuyant sur D).
                C'est un parcours en largeur du labyrinthe qui cherche le drapeau d'arrivée.
                Les cases blanches représentent les cases parcourues par l'algorithme lors de la recherche.
              </p>
              <video width="100%" height="100%" controls loop muted>
                <source src={DemoD} type="video/webm" />
              </video>
            </div>

            <div id="astar">
              <h3 className="text-center">Algorithme A*</h3>
              <p className="text-justify">
                Nous avons implémenté l'algorithme de A* (animé) du labyrinthe (en appuyant sur A).
                C'est le même algorithme que celui de Dijkstra mais avec une modification qui permet d'orienter la direction de la recherche et ainsi réduire le temps de parcours.
                Les cases rouges représentent les cases parcourues par l'algorithme lors de la recherche.
                Nous remarquons que ce nombre de cases est bien plus faible que celui obtenu avec Dijkstra.
              </p>
              <video width="100%" height="100%" controls loop muted>
                <source src={DemoA} type="video/webm" />
              </video>
            </div>

            <div id="pieces">
              <h3 className="text-center">Ramasser toutes les pièces</h3>
              <p className="text-justify">
                Nous avons implémenté un parcours (animé) qui va ramasser toutes les pièces (en appuyant sur Z).
                Le parcours obtenu n'est pas forcément optimal.
                En effet, nous générons beaucoup d'ordres aléatoires de parcours pour les pièces et sélectionnons le meilleur d'entre eux.
                Obtenir la solution à tous les coups est en O(n!) où n est le nombre de pièces à ramasser.
              </p>
              <video width="100%" height="100%" controls loop muted>
                <source src={DemoZ} type="video/webm" />
              </video>
            </div>

            <div id="ia">
              <h3 className="text-center">IA (Intelligence Artificielle)</h3>
              <p className="text-justify">
                Nous avons implémenté une IA qui essaie de ramasser toutes les pièces tout en esquivant les ennemis (en appuyant sur X).
                Si, par malheur, il n'y a pas de chemin sans ennemis disponible, le personnage va passer à travers l'ennemi et le tuer.
              </p>
              <video width="100%" height="100%" controls loop muted>
                <source src={DemoX} type="video/webm" />
              </video>
            </div>

          </div>
        </section>
      </React.Fragment >
    );
  }
}

export default Labyrinthe;