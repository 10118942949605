import React from "react";

// import components
import Navbar from "../navbar";

import pdf from "../../img/stageSolutec/GuillaumeGarrosSolutec.pdf";

class StageSolutec extends React.Component {
  constructor() {
    super();
    this.state = {
      title: "Développement d'une application d'aide à la recherche de logement cross-plateforme",
      navbar: [
        {
          id: "objectif",
          text: "Objectif",
        },
        {
          id: "rapport",
          text: "Rapport",
        },
      ]
    }
  }
  componentDidMount(){
    document.title = this.state.title
  }
  render() {
    return (
      <React.Fragment>
        <Navbar forceCompact={true} values={this.state.navbar} />
        <section id="pages" className="portfolio-mf sect-pt4 sect-mt4 route">
          <div className="container">
            <div className="title-box text-center">
              <h2 className="title-a">{this.state.title}</h2>
            </div>

            <div id="objectif">
              <h3 className="text-center">Objectif du Stage</h3>
              <p className="text-justify">
                L'objectif de ce stage est la conception et le développement d'une application crossplateforme d'assistance à la recherche de logements.
              </p>
              <p className="text-justify">
                Pour cela, nous utilisons le framework Flutter pour le frontend et Java Spring Boot pour le backend.
                La recommandation des logements est faite grâce à un modèle de machine learning.
                En ce qui concerne l'obtention des données de logements, nous combinons des méthodes de web scraping et des appels API pour extraire des offres de logements pertinentes.
              </p>
              <p className="text-justify">
                Au terme de ce stage, l'application a été développée avec succès et offre une expérience utilisateur fluide et conviviale.
              </p>
            </div>

            <div id="rapport">
              <h3 className="text-center">Rapport de stage</h3>
              <p className="text-justify">
                Mon rapport de stage est disponible <a
                  href={pdf}
                  target="_blank"
                  rel="noopener noreferrer"
                >ici</a>.
              </p>
            </div>

          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default StageSolutec;