import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";

//import images
import stageSolutec from "../img/stageSolutec/carteSuperCluster.png";
import apiProject from "../img/apiProject/Acceuil.png";
import openindoor from "../img/openindoor/Acceuil.png";
import blindSignature from "../img/blindSignature/ECClines-3.png";
import labyrinthe from "../img/labyrinthe/Acceuil.png";
import bdd from "../img/bdd/Acceuil.png";
import tipe from "../img/tipe/Acceuil.png";
import clavier from "../img/clavier/Acceuil.png";
import chocolat from "../img/chocolat/tablette_4x4_1.png";

class Portfolio extends React.Component {
  constructor() {
    super();
    this.works = [
      {
        id: "stageSolutec",
        title: "Stage au lab'SOLUTEC",
        description: "5 mois de stage de fin de 3<sup>ème</sup> année au lab'SOLUTEC",
        img: stageSolutec,
        technos: ["Flutter", "Java SpringBoot", "MongoDB"],
      },
      {
        id: "openindoor",
        title: "Développeur chez OpenIndoor",
        description: "5 mois de stage de fin de 2<sup>ème</sup> année chez OpenIndoor",
        img: openindoor,
        technos: ["Javascript", "SQL", "Python"],
      },
      {
        id: "apiProject",
        title: "API des réseaux sociaux",
        description: "Projet de 3<sup>ème</sup> année sur le développement d'une interface graphique d'interrogation d'API des réseaux sociaux",
        img: apiProject,
        technos: [ "Python", "API", "MongoDB"],
      },
      {
        id: "blindSignature",
        title: "Blind signature",
        description: "Projet de 2<sup>ème</sup> année sur l'implémentation et les attaques de blinds signatures",
        img: blindSignature,
        technos: ["C", "Cryptographie"],
      },
      {
        id: "labyrinthe",
        title: "Labyrinthe",
        description: "Projet de fin de 1<sup>ère</sup> année sur le thème du labyrinthe",
        img: labyrinthe,
        technos: ["C", "SDL2", "Algorithme"],
      },
      {
        id: "bdd",
        title: "Base de Données",
        description: "PhpMyAdmin et page html interactive",
        img: bdd,
        technos: ["HTML", "BDD", "PhpMyAdmin"],
      },
      {
        id: "tipe",
        title: "TIPE océan",
        description: "Recherche numérique du chemin le plus court sur l'océan",
        img: tipe,
        technos: ["Javascript", "SQL", "Python"],
      },
      {
        id: "clavier",
        title: "Clavier",
        description: "Programme pour améliorer sa dactylographie",
        img: clavier,
        technos: ["C++","C", "SDL2"],
      },
      {
        id: "chocolat",
        title: "Jeu du chocolat",
        description: "Étude mathématique du jeu du chocolat",
        img: chocolat,
        technos: ["Calculatrice"],
      },
    ];
  }
  render() {
    return (
      <section id="work" className="portfolio-mf sect-pt4 route">
        <div className="container">
          <div className="col-sm-12">
            <div className="title-box text-center">
              <h2 className="title-a">Voici mes derniers projets</h2>
              <p className="subtitle-a">
                Vous pouvez cliquer sur les projets pour avoir plus
                d'information.
              </p>
              <div className="line-mf"></div>
            </div>
          </div>
          <div className="row">
            {this.works.map((work) => {
              return (
                <React.Fragment key={work.id}>
                  <div className="col-md-4">
                    {/* <div className="work-box"> */}
                      <Link className="work-box" reloadDocument type="button" title="Voir plus" to={`/pages/${work.id}`}>
                        {/* <a href={stock} data-lightbox="gallery-vmarine"> */}
                        <div className="work-img">
                          {/* <img src={work.img} alt="" className="img-fluid" /> */}
                          <img src={work.img} alt="" />
                        </div>
                        <div className="work-content">
                          <div className="row">
                            <div className="col-sm-10">
                              <h2 className="w-title">{work.title}</h2>
                            </div>
                            <div className="col-sm-2">
                              <div className="w-like">
                                <FontAwesomeIcon icon={faCirclePlus} />
                              </div>
                            </div>
                          </div>
                          <div className="w-more">
                            <p className="text-justify" dangerouslySetInnerHTML={{__html:work.description}}></p>
                            <span className="w-ctegory">
                              {work.technos.map((techno) => {
                                return (
                                  <div key={techno} className="programing-tag">
                                    {techno}
                                  </div>
                                );
                              })}
                            </span>
                          </div>
                        </div>
                      </Link>
                    {/* </div> */}
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </section>
    );
  }
}

export default Portfolio;
