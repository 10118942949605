import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Outlet,
} from "react-router-dom";

//import components
import Navbar from "./navbar.jsx";
import Intro from "./intro.jsx";
import Portfolio from "./portfolio.jsx";
import About from "./about.jsx";
import Contact from "./contact.jsx";
import BackToTop from "./back-top.jsx";
import Preloader from "./preloader.jsx";

import StageSolutec from "./pages/stageSolutec.jsx";
import Openindoor from "./pages/openindoor.jsx";
import Chocolat from "./pages/chocolat.jsx";
import Clavier from "./pages/clavier.jsx";
import Tipe from "./pages/tipe.jsx";
import Bdd from "./pages/bdd.jsx";
import Labyrinthe from "./pages/labyrinthe.jsx";
import BlindSignature from "./pages/blindSignature.jsx";
import ApiProject from "./pages/apiProject.jsx";


const Index = () => {
  return (
    <>
      <Navbar forceCompact={false}/>
      <Intro />
      <About />
      <Portfolio />
      <Preloader />
    </>
  )
}

const Layout = () => {
  return (
    <>
      <div>
        <Outlet />
      </div>
      <Contact />
      <BackToTop />
    </>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route index path="/" element={<Index />} />
      <Route path="/pages/stageSolutec" element={<StageSolutec />}/>
      <Route path="/pages/openindoor" element={<Openindoor />}/>
      <Route path="/pages/apiProject" element={<ApiProject />}/>
      <Route path="/pages/blindSignature" element={<BlindSignature />}/>
      <Route path="/pages/labyrinthe" element={<Labyrinthe />}/>
      <Route path="/pages/bdd" element={<Bdd />}/>
      <Route path="/pages/tipe" element={<Tipe />}/>
      <Route path="/pages/clavier" element={<Clavier />}/>
      <Route path="/pages/chocolat" element={<Chocolat />}/>
    </Route>
  )
);

const App = () => {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
};

export default App;